import { FadeInOut, WideGradient } from '@components';
import { routes } from '@constants';
import { useScreenSize } from '@hooks';
import NoiseImage from '@pngs/Noise5.png';
// import { scenarioApi } from '@store';
import { Button, Flex, theme, Typography } from '@styles';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import StarsGroup from '../../../components/StarsGroup/StarsGroup';
import {
  FINISH_SECTION_SUBTITLE_BY_COMPLETED_STEPS_AMOUNT,
  finishSection,
  scenarioPageLayoutByVariant,
} from '../ScenarioPage.layoutProps';

const FinishSection = ({
  isFinishCardShown,
  isNextBtnShown,
  onNextClick,
  scenario,
}) => {
  const navigate = useNavigate();
  const { device, isTabletSM } = useScreenSize();
  const i18n = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  /* 
     Make sure to call onNextClick only when isLoading is true to smooth UI transition
     and prevent scenario data blink
  */
  useEffect(() => {
    if (isLoading) {
      onNextClick();
    }
  }, [isLoading]);

  const handleNextBtnClick = () => {
    setIsLoading(true);
  };

  const layout = scenarioPageLayoutByVariant[device || 'desktop'];

  const goToMap = () => {
    navigate(routes.map);
  };

  const completedStepsAmount = scenario?.completed_steps_amount || 0;
  const type = completedStepsAmount === 0 ? 'failed' : 'success';

  const layoutProps =
    scenarioPageLayoutByVariant[device || 'desktop'].finishSection;

  return (
    <FadeInOut
      duration={500}
      show={isFinishCardShown}
      style={{
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      }}
    >
      <Flex
        alignItems="center"
        backgroundBlendMode="overlay"
        backgroundColor={theme.colors.black[70]}
        backgroundImage={NoiseImage}
        backgroundSize="100%"
        height="100%"
        justifyContent="center"
        width="100%"
        {...layout}
      >
        {isLoading ? (
          <ClipLoader color="#FFF504" size="60px" speedMultiplier={0.7} />
        ) : (
          <Flex
            alignItems="center"
            flexDirection="column"
            height="100%"
            justifyContent="space-between"
            p={24}
            pb={isTabletSM ? 24 : 80}
            position="relative"
            width="80%"
          >
            <Flex
              alignItems="center"
              color={finishSection[type].color}
              flexDirection="column"
            >
              <Typography {...layoutProps.title}>
                {i18n.t(finishSection[type].title)}
              </Typography>
              <Typography {...layoutProps.subtitle}>
                {i18n.t(
                  FINISH_SECTION_SUBTITLE_BY_COMPLETED_STEPS_AMOUNT[
                    completedStepsAmount
                  ]
                )}
              </Typography>
              <Typography {...layoutProps.levelNumber}>
                # {scenario?.level_number}
              </Typography>
            </Flex>
            <StarsGroup
              completedStarsAmount={completedStepsAmount}
              marginBottom={layoutProps.starsGroup.marginBottom}
            />
            <Flex
              flexDirection={isTabletSM ? 'column' : 'row'}
              gap={16}
              justifyContent={isNextBtnShown ? 'space-between' : 'center'}
              width="100%"
            >
              <Flex
                backgroundColor={theme.colors.white[10]}
                borderRadius="4px"
                flex={0.5}
                height={isTabletSM ? 69 : 80}
                minHeight={69}
              >
                <Button onClick={goToMap} variant="textButton" width="100%">
                  <Typography
                    color={theme.colors.white[100]}
                    variant={
                      isTabletSM
                        ? 'MS-semibold-s16-lh25'
                        : 'MS-semibold-s27-lh32'
                    }
                  >
                    {i18n.t('Scenario.Passed.ToMap')}
                  </Typography>
                </Button>
              </Flex>
              {isNextBtnShown ? (
                <Flex
                  backgroundColor={theme.colors.white[10]}
                  borderRadius="4px"
                  flex={1 / 2}
                  height={isTabletSM ? 69 : 80}
                  minHeight={69}
                >
                  <Button
                    onClick={handleNextBtnClick}
                    variant="textButton"
                    width="100%"
                  >
                    <Typography
                      color={theme.colors.white[100]}
                      variant={
                        isTabletSM
                          ? 'MS-semibold-s16-lh25'
                          : 'MS-semibold-s27-lh32'
                      }
                    >
                      {i18n.t('Scenario.Passed.Next')}
                    </Typography>
                  </Button>
                </Flex>
              ) : null}
            </Flex>
            <WideGradient
              left={isTabletSM ? -50 : -20}
              opacity={0.25}
              position="absolute"
              style={{ pointerEvents: 'none' }}
              top={isTabletSM ? -500 : -400}
              {...(isTabletSM && { width: 600 })}
            />
          </Flex>
        )}
      </Flex>
    </FadeInOut>
  );
};

FinishSection.propTypes = {
  isFinishCardShown: bool.isRequired,
  isNextBtnShown: bool,
  onNextClick: func.isRequired,
  scenario: object.isRequired,
};

FinishSection.defaultProps = {
  isNextBtnShown: true,
};

export default FinishSection;
