import { theme } from '@styles';

export const scenarioPageLayoutByVariant = {
  desktop: {
    finishSection: {
      levelNumber: {
        lineHeight: '150%',
        variant: 'BN-bold-s80-lh84',
      },
      starsGroup: {
        marginBottom: 94,
      },
      subtitle: {
        variant: 'MS-regular-s32-lh50',
      },
      title: {
        variant: 'BN-bold-s120-lh105',
      },
    },
    overflow: 'scroll',
    pb: 64,
    pl: 104,
    pr: 104,
    pt: 64,
  },
  laptop: {
    finishSection: {
      levelNumber: {
        lineHeight: '120%',
        variant: 'BN-bold-s80-lh84',
      },
      starsGroup: {
        marginBottom: 80,
      },
      subtitle: {
        variant: 'MS-regular-s30-lh36',
      },
      title: {
        variant: 'BN-bold-s96-lh105',
      },
    },
    overflow: 'scroll',
    pb: 24,
    pl: 64,
    pr: 64,
    pt: 24,
  },
  mobile: {
    finishSection: {
      levelNumber: {
        lineHeight: '120%',
        variant: 'BN-bold-s56-lh67',
      },
      starsGroup: {
        marginBottom: 40,
      },
      subtitle: {
        variant: 'MS-semiregular-s18-lh22',
      },
      title: {
        variant: 'BN-bold-s56-lh67',
      },
    },
    overflow: 'scroll',
    pb: 16,
    pl: 16,
    pr: 16,
    pt: 40,
  },
  tablet: {
    finishSection: {
      levelNumber: {
        lineHeight: '120%',
        variant: 'BN-bold-s56-lh67',
      },
      starsGroup: {
        marginBottom: 60,
      },
      subtitle: {
        variant: 'MS-semiregular-s18-lh22',
      },
      title: {
        variant: 'BN-bold-s56-lh67',
      },
    },
    overflow: 'scroll',
    pb: 16,
    pl: 16,
    pr: 16,
    pt: 40,
  },
};

export const finishSection = {
  failed: {
    color: theme.colors.alto[30],
    subtitle: 'Scenario.Passed.Failed.Subtitle',
    title: 'Scenario.Passed.Failed.Title',
  },
  success: {
    color: theme.colors.white[100],
    subtitle: 'Scenario.Passed.Success.Subtitle',
    title: 'Scenario.Passed.Success.Title',
  },
};

export const FINISH_SECTION_SUBTITLE_BY_COMPLETED_STEPS_AMOUNT = {
  0: 'Scenario.Passed.Failed.Subtitle',
  1: 'Scenario.Passed.Moderate.Subtitle',
  2: 'Scenario.Passed.Moderate.Subtitle',
  3: 'Scenario.Passed.Success.Subtitle',
};
