/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import { CompletionModal, DifficultyCompletionModal } from '@components';
import NoiseImage from '@pngs/Noise5.png';
import { scenarioApi } from '@store';
import { Flex, theme } from '@styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import { findSkippedStepIndex } from './ScenarioPage.helpers';
import FinishSection from './sections/FinishSection';
import StepsSection from './sections/StepsSection';
import TitleSection from './sections/TitleSection';

const ScenarioPage = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const { language } = useSelector((state) => state.authSlice);
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [currentSection, setCurrentSection] = useState(
    location.state?.openedFromPreviousScenario ? 'title' : 'steps'
  );
  const [isCardShown, setIsCardShown] = useState(true);
  const [isFinishCardShown, setIsFinishCardShown] = useState(false);
  const [isCompletionModalOpen, setIsCompletionModalOpen] = useState(false);
  const [isDifficultyModalOpen, setIsDifficultyModalOpen] = useState(false);
  const [finishedStepIndices, setFinishedStepIndices] = useState([]);

  const { isLoading, refetch, scenario } = scenarioApi.useGetScenarioByIdQuery(
    { id },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data }) => ({
        scenario: data?.data,
      }),
    }
  );

  useEffect(() => {
    if (currentSection === 'finish') return;

    if (isCardShown) {
      if (currentSection !== 'steps') {
        setTimeout(() => {
          setIsCardShown(false);
        }, 2000);
      }
      setTimeout(() => {
        if (currentSection === 'title') {
          setCurrentSection('steps');
        }
      }, 2500);
    } else {
      setTimeout(() => {
        setIsCardShown(true);
      }, 1500);
    }
  }, [isCardShown]);

  useEffect(() => {
    refetch({ id });
  }, [i18n.language, language]);

  const handleAllLevelsCompletion = () => {
    if (scenario?.is_last_in_diff_level) {
      if (scenario?.difficulty_level?.order_number === 3) {
        setIsCompletionModalOpen(true);
      } else {
        setIsDifficultyModalOpen(true);
      }
    }
  };

  const showFinishCard = () => {
    setIsCardShown(false);
    setCurrentSection('finish');
    setIsFinishCardShown(true);
  };

  const handleNextButtonClick = () => {
    if (currentStep === scenario?.steps.length) return;

    if (finishedStepIndices.length === scenario?.steps.length) {
      showFinishCard();
    } else {
      const nextStepIndex = findSkippedStepIndex({
        finishedStepIndices,
        stepsAmount: scenario?.steps.length,
      });

      setCurrentStep(nextStepIndex);
    }
  };

  const handleCurrentStepChange = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const handleStepFinish = () => {
    refetch({ id });

    if (finishedStepIndices.includes(currentStep)) return;

    setFinishedStepIndices([...finishedStepIndices, currentStep]);
  };

  const navigateToNextScenario = () => {
    navigate(`/scenarios/${scenario?.next_scenario_id}`, {
      state: { openedFromPreviousScenario: true },
    });
    window.location.reload();
  };

  const goToNextScenario = () => {
    setIsFinishCardShown(false);

    if (scenario?.is_last_in_diff_level) {
      handleAllLevelsCompletion();
      return;
    }

    navigateToNextScenario();
  };

  const shouldNextButtonBeShown = !!(
    scenario?.next_scenario_id || scenario?.is_last_in_diff_level
  );

  const sections = {
    finish: (
      <FinishSection
        isFinishCardShown={isFinishCardShown}
        isNextBtnShown={shouldNextButtonBeShown}
        onNextClick={goToNextScenario}
        scenario={scenario}
      />
    ),
    steps: (
      <StepsSection
        currentStep={currentStep}
        isCardShown={isCardShown}
        onContinue={handleNextButtonClick}
        onFinish={showFinishCard}
        onStepChange={handleCurrentStepChange}
        onStepFinish={handleStepFinish}
        scenario={scenario}
      />
    ),
    title: <TitleSection isCardShown={isCardShown} scenario={scenario} />,
  };

  const handleDifficultyCompletionModalClose = () => {
    setIsDifficultyModalOpen(false);

    navigateToNextScenario();
  };

  return (
    <Flex
      alignItems="center"
      backgroundBlendMode="overlay"
      backgroundImage={NoiseImage}
      backgroundSize="100%"
      height="100vh"
      justifyContent="center"
      overflow="hidden"
      position="relative"
    >
      <CompletionModal
        isModalVisible={isCompletionModalOpen}
        setModalVisible={setIsCompletionModalOpen}
      />
      <DifficultyCompletionModal
        difficulty={scenario?.difficulty_level}
        isModalVisible={isDifficultyModalOpen}
        onSubmit={handleDifficultyCompletionModalClose}
        setModalVisible={setIsDifficultyModalOpen}
      />
      {isLoading || !scenario ? (
        <ClipLoader color={theme.colors.vividYellow[100]} size="60px" />
      ) : (
        sections[currentSection]
      )}
    </Flex>
  );
};

export default ScenarioPage;
